import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { useTrackingEstimatedDelivery } from 'hooks'
import { useQuery } from 'apollo-client'

import { NavItem } from 'compositions/navigation'
import { navMessages } from 'compositions/navigation'

import ordersQuery from './graph/orders.graphql'

import messages from './messages'


const TrackingLink: React.FunctionComponent = () => {
  const { data } = useQuery(ordersQuery, {
    variables: {
      input: {
        offset: 0,
        limit: 5,
        sort: [ 'YEAR_DESC', 'MONTH_DESC' ],
        orderType: [ 'SUBSCRIPTION', 'SUBSCRIPTION_CUSTOM_SHIPMENT', 'REPLACEMENT' ],
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    ssr: false,
  })

  const orders = data?.currentUser?.data?.orders
  const { data: items = [] } = orders || {}
  const latestOrderInTransit = items.find((item) => item.shipment?.tracking?.status === 'IN_TRANSIT' && Boolean(item.shipment?.tracking?.url))
  const estimatedDeliveryDate = useTrackingEstimatedDelivery(latestOrderInTransit?.shipment?.tracking?.url)

  const titleMessage = Boolean(estimatedDeliveryDate) ? (
    { ...messages.estimatedDelivery, values: { estimatedDeliveryDate } }
  ) : (
    navMessages.track
  )

  return (
    <NavItem
      className="inline-block"
      style="p4"
      to={links.tracking}
      title={titleMessage}
      onClick={() => {
        track('Track your order click', {
          placement: 'Header',
        })
      }}
    />
  )
}


export default TrackingLink
