import React from 'react'
import links from 'links'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import backgroundImage from './images/background.jpg'


const SamplesSubscriptionBanner: React.FunctionComponent<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.samplesSubscription}
      bgImage={backgroundImage}
      content="Samples subscription"
      sizes="560px"
      data-testid="samplesSubscriptionBanner"
      onClick={onClick}
    />
  )
}


export default SamplesSubscriptionBanner
